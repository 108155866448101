import React from 'react'
import { PageLink } from 'src/components/link'

import Masonry from 'react-masonry-css'

import { RenderModules } from 'src/utils/renderModules'
import { SEO } from 'src/components/SEO'
// import Dummy from 'dummyjs'

import { useStaticQuery, graphql } from "gatsby";


import styled from 'styled-components'

const Main = styled.div`
  display: grid;
  grid-area: Main;
  margin: 0 auto;
  min-height: 100%;
	grid-template-columns: 100%;
  justify-content: center;
` 

// let items : Array<{ id: number, name: string}> = [
//     {id: 1, name: Dummy.text(10,18)},
//     {id: 2, name: Dummy.text(10,18)},
//     {id: 3, name: Dummy.text(10,18)},
//     {id: 4, name: Dummy.text(10,18)},
//     {id: 5, name: Dummy.text(10,18)}
//   ];
  

// let newsArticles = items.map(function(item) {
//     return (
//     <div key={item.id}>
//       Item #{item.id}
//       {/* <img src={Dummy.img(500,400)} /> */}
//       {item.name}
//     </div>
    
//     )
// })
// console.log(newsArticles);

// const myBreakpointsAndCols = {
//     default: 3,
//     1100: 3,
//     700: 2,
//     500: 1
//   };



export interface NewsProps {
    pageContext: {
      modules: []
      slug: string
      main: {
        title: string
      },
      meta: {}
    }
    path?: string
    preview?: boolean
  }
  
  
  
  const News = ({
    path,
    pageContext,
    preview = false
  }: NewsProps) => {
    const {
      modules,
      slug,
      meta
    } = pageContext
  
    const data = useStaticQuery(graphql`
    {
      allSanitySiteGlobal {
        nodes {
          content {
            globalSettings {
              globalMarquee
              globalTitle
              globalPhoneNumber
            }
          }
        }
      }
    }
    `);

    const url = slug === 'home' ? '' : path
    return (
      <Main>
        {preview && (
            <div className='bcblue ac cw x p1'>This is a Preview</div>
        )}
        <SEO metaInfo={meta} pagePath={url} />
        
        
        
        <Masonry
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        /News
       News Page
      </Masonry>

      {RenderModules(modules)}
      </Main>
    )
  }
  export default News